/* NAVBAR STYLES */
.navbar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70px;
    min-width: 70px;
    height: 100vh;
    background-color: #5a155d;
    padding: 5px 0px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
}

.logo-container {
    cursor: pointer;
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
}

.app-logo {
    height: 50px;
    width: 50px;
    filter: brightness(0) saturate(100%) invert(52%) sepia(85%) saturate(188%) hue-rotate(262deg) brightness(91%) contrast(88%);
}

.navbar-items {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.navbar-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.4s ease;
    width: 100%;
    border-radius: 8px;
    text-align: center;
    background-color: transparent;
    border: 2px solid transparent;
}

.navbar-item:hover {
    background-color: #ffdf02;
    color: #5a155d !important;
    transform: scale(1.1);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    border: 2px solid #5a155d;
}

.navbar-item:hover .icon,
.navbar-item:hover .field-name {
    color: #5a155d !important;
}

.icon {
    font-size: 20px;
    color: #ffdf02;
    transition: color 0.3s ease;
}

.field-name {
    font-size: 10px;
    color: #ffdf02;
    margin-top: 3px;
    text-align: center;
    transition: color 0.3s ease;
}

.navbar-item.active {
    background-color: #ffdf02;
    color: #5a155d;
    transform: translateY(-2px);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    border: 2px solid #5a155d;
    transition: all 0.4s ease;
}

.navbar-item.active .icon {
    color: #5a155d;
}

.navbar-item.active .field-name {
    color: #5a155d;
}

.navbar-link {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logout-btn {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

/* MODAL STYLES */
.logout-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.logout-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 300px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-in-out;
}

.logout-modal-content h3 {
    margin-bottom: 10px;
    color: #5a155d;
}

.lohout-modal-content p {
    font-size: 14px;
    color: #333;
    margin-bottom: 15px;
}

.logout-modal-actions {
    display: flex;
    justify-content: space-between;
}

.logout-confirm-btn {
    background: #d9534f;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.logout-confirm-btn:hover {
    background: #c9302c;
}

.logout-cancel-btn {
    background: #6c757d;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.logout-cancel-btn:hover {
    background: #5a6268;
}

/* ANIMATION */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* RESPONSIVE DESIGN */
@media screen and (max-width: 768px) {
    .navbar-container {
        width: 60px;
    }

    .field-name {
        font-size: 9px;
    }
    
    .icon {
        font-size: 18px;
    }

    .logout-modal-content {
        width: 80%;
    }
}
