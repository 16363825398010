.form-container { 
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(120deg, #3a1c3b, #6a2075, #44154f, #8b369f);
  background-size: 400% 400%;
  animation: moveBackground 6s infinite alternate;
}

@keyframes moveBackground {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

.input-form {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 450px;
}

.country-name{
  margin-left: 60px;
}

.react-tel-input .selected-flag.open:before{
  border-color: #5a155d;
  box-shadow: 0 0 2px 2px rgba(90, 21, 93, 0.7)
}

.input-group {
  flex: 1;
  min-width: 150px;
  margin-bottom: 15px;
}

.input-field {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  padding-left: 55px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
}

.input-field-iconless {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  padding-left: 25px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
}

.input-field::placeholder,
.input-field-iconless::placeholder {
    color: #5a155d;
    font-style: italic;
}

.input-field:focus {
    border-color: #5a155d;
    box-shadow: 0 0 5px rgba(90, 21, 93, 0.5);
}

.otp-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.otp-inputs {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 20px 0;
}

.otp-input {
  width: 50px;
  height: 50px;
  font-size: 18px;
  text-align: center;
  border: 1px solid #4b083f;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(39, 2, 31, 0.1);
}

.otp-input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(90, 21, 93, 0.5);
}

.resend-btn {
  background: none;
  color: #6a2075;
  border: none;
  cursor: pointer;
}

.verification-message {
  text-align: end;
  font-size: 16px;
  color: #5a155d;
}

.verification-message p {
  font-size: 16px;
  font-weight: bold;
  color: #5a155d;
}

.password-toggle {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-60%);
  cursor: pointer;
  color: #5a155d;
}

.password-toggle:hover {
  color: #8b369f;
}

.verify-btn-container {
  text-align: center;
  margin-bottom: 20px;
}

.verify-btn {
  background-color: #5a155d;
  color: #ffdf02;
  padding: 10px 20px;
  width: 130px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.verify-btn:hover {
  background-color: #4a114d;
}

.contact-details {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #5a155d;
}

.contact-details b {
  color: #5a155d;
}

.submit-btn {
  width: 45%;
  padding: 12px;
  background-color: #ffdf02;
  color: #5a155d;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.submit-btn:hover {
  background-color: #f0d702;
}

.form-label {
  font-size: 1rem;
  font-weight: 600;
  color: #5a155d; /* Violet color for the label */
}

.alert {
  font-size: 1rem;
  color: #5a155d; /* Violet color for alerts */
}

.send-otp-btn {
  width: 100%;
  padding: 10px;
  background-color: #5a155d;
  color: #ffdf02;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.send-otp-btn:hover{
  background-color: #4a114d;
  /* color: #fff; */
}

.back-to-login-link {
  position: absolute;
  top: 8px;
  right: 10px;
}

.back-link {
  font-size: 16px;
  font-weight: bold;
  color: #5a155d;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.back-icon {
  margin-left: 5px;
  font-size: 20px;
}

.link-primary {
  color: #5a155d;
  text-decoration: none;
}

.link-primary:hover {
  text-decoration: underline;
  color: #ffdf02;
}

.success-message {
  color: #4CAF50;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
