.input-group-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.input-group-div label {  
  font-size: 1rem;
  font-weight: 600;
  color: #555;
}

.input-group-div input,
.input-group-div select {
  padding: 10px 12px;
  font-size: 1rem;
  border: 1px solid #d1d1d1;
  width: 600px  ;
  max-width: 80%;
  border-radius: 8px;
  background-color: #fff;
  transition: border 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.input-group-div input:focus,
.input-group-div select:focus {
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.4);
  outline: none;
}

.input-group-div select {
  cursor: pointer;
}

.select-component{
  width: 100%;
    padding: 8px;
    border: 2px solid #9933ff;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.select-option {
  background-color: #9933ff;
  color: #fff;
}

.error-message {
  margin: 10px 0;
  padding: 10px;
  background-color: #ffe6e6;
  color: #d9534f;
  border: 1px solid #d9534f;
  border-radius: 4px;
  font-size: 0.9rem;
}
