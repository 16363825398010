.main-container {
    display: flex;
    height: 100vh;
}

.content-container {
    flex-grow: 1;
    background-color: #f4f4f4; /* Light grey background */
}

.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #5a155d;
    color: #ffdf02;
    padding: 5px 30px;
    margin-bottom: 10px;
}

.shop-name {
    font-size: 24px;
    font-weight: bold;
}

.credits-container {
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.credits {
    font-size: 18px;
    color: #5a155d;
    display: flex;
    align-items: center;
    gap: 8px;
}

.credits .amount,
.credits .status {
    font-weight: bold;
}

.amount.red-text,
.status.red-text {
    color: red;
}

.amount.green-text,
.status.green-text {
    color: green;
}

.credits .status {
    font-size: 16px;
}

.credits-container p {
    margin: 0;
    padding: 0;
}

.credits-container .fa-credit-card {
    font-size: 20px;
    margin-right: 8px; /* Space between the icon and text */
}

/* ✅ Full-screen overlay */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  /* ✅ Responsive Payment Dialog */
  .payment-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 90%;
    max-width: 450px; /* ✅ Limits width on larger screens */
    z-index: 1000;
  }
  
  .payment-content h3 {
    color: #d32f2f;
    margin-bottom: 10px;
  }
  
  .qr-container {
    margin-top: 15px;
  }
  
  .qr-instructions {
    font-size: 14px;
    margin-top: 10px;
  }
  
  /* ✅ Show current balance */
  .current-balance {
    margin: 10px 0;
    font-size: 18px;
  }
  
  .balance-amount {
    font-weight: bold;
    margin-left: 8px;
  }
  
  .red-text {
    color: red;
  }
  
  .green-text {
    color: green;
  }
  
  /* ✅ Close Button */
  .close-btn {
    margin-top: 15px;
    background: #d32f2f;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .close-btn:hover {
    background: #b71c1c;
  }
  
  /* ✅ Responsive for Mobile */
  @media (max-width: 600px) {
    .payment-dialog {
      width: 90%;
    }
  }
  
.new-order-container {
    margin: 5px 10px;
    text-align: right;
}

.new-order-btn {
    display: inline-flex;
    align-items: center;
    background-color: #5a155d;
    color: #ffdf02;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s ease;
}

.new-order-btn .icon {
    margin-right: 8px;
}

.new-order-btn:hover {
    background-color: #832491; /* Slightly lighter violet on hover */
    transform: scale(1.05);
}

.dash-table-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 70%;
    overflow-y: auto;
    position: relative;
}

/* Scrollbar styling for table container */
.dash-table-container::-webkit-scrollbar {
    width: 8px;
}

.dash-table-container::-webkit-scrollbar-thumb {
    background-color: #832491; /* Violet scrollbar thumb */
    border-radius: 4px;
}

.dash-table-container::-webkit-scrollbar-track {
    background-color: #f4f4f4; /* Light grey scrollbar track */
}
.orders-table {
    width: 100%;
    border-collapse: collapse;
}

.orders-table thead {
    background-color: #5a155d; /* Violet table header */
    color: #ffdf02; /* Yellow text */
    top: 0;
    z-index: 1;
}

.orders-table th,
.orders-table td {
    text-align: left;
    padding: 12px 15px;
    border: 1px solid #ddd;
}

.orders-table th {
    font-size: 16px;
    font-weight: bold;
}

.orders-table td {
    font-size: 14px;
}

.orders-table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.orders-table tbody tr:hover {
    background-color: #fff1ab; /* Light yellow for hover */
}
