.prices-page-container {
    display: flex;
    height: 100vh;
    font-family: 'Arial', sans-serif;
}

.prices-content {
    flex-grow: 1;
    background-color: #f4f4f4;
    overflow-y: auto;
}

.prices-header {
    margin-top: 40px;
    text-align: center;
    margin-bottom: 20px;
}

.prices-header h1 {
    font-size: 2.5rem;
    color: #333;
}

.price-type {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #555;
}

.price-type h2 {
    font-size: 1.5rem;
    margin: 0;
}

.price-cards-container {
    display: flex;
    margin-top: 10vh;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.price-cards {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

.price-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 220px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.price-card h3 {
    font-size: 1.25rem;
    color: #444;
    margin-bottom: 10px;
}

.price-value {
    font-size: 2rem;
    font-weight: bold;
    color: #27ae60;
}

.price-description {
    font-size: 1rem;
    color: #777;
}

.loading-message {
    text-align: center;
    color: #777;
    font-size: 1.2rem;
}

.price-card .price-value {
    color: #27ae60; /* Green color for pricing */
}
