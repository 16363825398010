.form-container { 
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(120deg, #3a1c3b, #6a2075, #44154f, #8b369f);
  background-size: 400% 400%;
  animation: moveBackground 6s infinite alternate;
}

@keyframes moveBackground {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

.input-form {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 450px;
}

.logo {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.logo-img {
  height: 50px;
  width: 50px;
}

.country-name{
  margin-left: 60px;
}

.react-tel-input .selected-flag.open:before{
  border-color: #5a155d;
  box-shadow: 0 0 2px 2px rgba(90, 21, 93, 0.7)
}

.form-heading {
  text-align: center;
  font-size: 24px;
  color: #5a155d;
  margin-bottom: 20px;
}

.input-label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #5a155d;
}

.input-icon {
  font-size: 18px;
  color: #5a155d;
  position: absolute;
  left: 10px;
  top: 13px;
}
/* .progress-bar-container {
  width: 100%;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 10px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: #5a155d;
  transition: width 0.4s ease-in-out;
} */
.input-field {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  padding-left: 55px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
}

.input-field-iconless {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  padding-left: 25px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
}

.input-field::placeholder,
.input-field-iconless::placeholder {
    color: #5a155d;
    font-style: italic;
}

.input-field:focus {
    border-color: #5a155d;
    box-shadow: 0 0 5px rgba(90, 21, 93, 0.5);
}

.otp-sent-message {
  text-align: center;
  font-size: 16px;
  color: #5a155d;
  margin-bottom: 20px;
}

.otp-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.otp-inputs {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 20px 0;
}

.otp-input {
  width: 50px;
  height: 50px;
  font-size: 18px;
  text-align: center;
  border: 1px solid #4b083f;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(39, 2, 31, 0.1);
}

.otp-input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(90, 21, 93, 0.5);
}

.resend-btn {
  background: none;
  color: #6a2075;
  border: none;
  cursor: pointer;
}

.contact-details {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #5a155d;
}

.contact-details b {
  color: #5a155d;
}

.next-btn, .submit-btn {
  width: 45%;
  padding: 12px;
  background-color: #ffdf02;
  color: #5a155d;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.next-btn:hover, .submit-btn:hover {
  background-color: #f0d702;
}

.prev-btn {
  width: 45%;
  padding: 12px;
  background-color: #d3d3d3;
  color: #333333; /* Dark gray text */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

.prev-btn:hover {
  background-color: #a9a9a9;
  color: #ffffff;
}

.verification-message {
  text-align: end;
  font-size: 16px;
  color: #5a155d;
}

.verification-message p {
  font-size: 16px;
  font-weight: bold;
  color: #5a155d;
}

.password-toggle {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-60%);
  cursor: pointer;
  color: #5a155d;
}

.password-toggle:hover {
  color: #8b369f;
}

.input-row {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.login-link {
  text-decoration: none;
  color: #5a155d;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.login-link span {
  margin-right: 5px;
  font-size: 18px;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.next-btn-div {
  display: flex;
  justify-content: flex-end;
}

.right-align {
  width: 45%;
  padding: 12px;
  background-color: #ffdf02;
  color: #5a155d;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.right-align:hover {
  background-color: #f0d702;
}

.approval-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.approval-card {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.approval-card h3 {
  font-size: 28px;
  color: #5a155d;
  margin-bottom: 15px;
}

.approval-card p {
  font-size: 16px;
  color: #555;
}

.resend-otp {
  text-align: right;
  margin-top: 10px;
}

.resend-btn:hover {
  text-decoration: underline;
  color: #5a155d;
}

.back-to-login-container {
  text-align: center;
  margin-top: 20px;
}

.back-to-login-btn {
  background-color: #5a155d;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.back-to-login-btn:hover {
  background-color: #4a114d;
}

.back-arrow {
  margin-right: 8px;
  font-size: 18px;
}

.input-group {
  flex: 1;
  min-width: 150px;
  margin-bottom: 15px;
}

.input-group-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.image-preview-r {
  margin-top: 10px;
  width: 100%;
  max-width: 350px;
  height: 100px;
  overflow: hidden;
  border: 2px solid #ddd;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
}

.preview-img-r {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.error-group {
  margin: 0;
  padding: 0;
}

.error {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 0;
  color: rgb(246, 78, 78);
}
