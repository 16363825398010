.form-container { 
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(120deg, #3a1c3b, #6a2075, #44154f, #8b369f);
  background-size: 400% 400%;
  animation: moveBackground 6s infinite alternate;
}

@keyframes moveBackground {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

.input-form {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 450px;
}

.logo {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.logo-img {
  height: 50px;
  width: 50px;
}

.country-name{
  margin-left: 60px;
}

.react-tel-input .selected-flag.open:before{
  border-color: #5a155d;
  box-shadow: 0 0 2px 2px rgba(90, 21, 93, 0.7)
}

.form-heading {
  text-align: center;
  font-size: 24px;
  color: #5a155d;
  margin-bottom: 20px;
}

.input-group {
  flex: 1;
  min-width: 150px;
  margin-bottom: 15px;
}

.input-icon {
  font-size: 18px;
  color: #5a155d;
  position: absolute;
  left: 10px;
  top: 13px;
}

.input-field {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  padding-left: 55px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
}

.password-toggle {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-60%);
  cursor: pointer;
  color: #5a155d;
}

.password-toggle:hover {
  color: #8b369f;
}

.contact-details {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #5a155d;
}

.contact-details b {
  color: #5a155d;
}

.input-field::placeholder {
    color: #5a155d;
    font-style: italic;
}

.input-field:focus {
    border-color: #5a155d;
    box-shadow: 0 0 5px rgba(90, 21, 93, 0.5);
}

.login-btn {
  width: 100%;
  padding: 12px;
  background-color: #5a155d;
  color: #ffdf02;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.login-btn:hover,
.login-btn:focus{
  background-color: #4a114d;
}

.links {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.forgot-password {
  color: #5a155d;
  text-decoration: none;
}

.forgot-password:hover {
  text-decoration: underline;
}

.no-account {
  font-size: 14px;
}

.no-account a {
  color: #5a155d;
  text-decoration: none;
}

.no-account a:hover {
  text-decoration: underline;
}

.contact-details b {
  font-weight: bold;
  color: #5a155d;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 15px;
}

@media (max-width: 600px) {
  .links {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}