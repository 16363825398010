.new-order-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(19, 17, 17, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    pointer-events: auto;
}

.choice-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    height: 90vh;
    background-color:  #e6ccff;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    z-index: 10;
    border: 3px solid #9933ff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.9);
    overflow: hidden;
}

.choice-modal h3 {
    margin-top: 5px;
    font-size: 30px;
    color: rgb(100, 2, 100);
    font-weight: bold;
}

.choices-container {
    display: grid;
    gap: 10px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    justify-items: center;
    margin-top: 20px;
    height: 72%;
    overflow-y: auto;
}

.choices-container.grid-2x2 {
    grid-template-columns: repeat(2, 1fr);
}

.choices-container.grid-2x3 {
    grid-template-columns: repeat(3, 1fr);
}

.choices-container.grid-1{
    grid-template-columns: repeat(1,1fr);
}

.choices-container.grid-2{
    grid-template-columns: repeat(2,1fr);
}

.choice-card {
    display: flex;
    flex-direction: column;
    padding: 15px 0px;
    text-align: center;
    background-color: #f4f3f8;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    max-width: 240px;
    max-height: 220px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    color: rgb(100, 2, 100);
    border: 2px solid transparent;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    transition: background-color 0.3s, transform 0.2s, color 0.3s, border 0.3s;
}

.choice-card-slip-size{
    display: flex;
    flex-direction: column;
    padding: 15px 0px;
    text-align: center;
    background-color: #f4f3f8;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    max-width: 300px;
    max-height: 250px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    color: rgb(100, 2, 100);
    border: 2px solid transparent;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    transition: background-color 0.3s, transform 0.2s, color 0.3s, border 0.3s;
}

.choice-image {
    max-width: 100%; /* Ensure it doesn't overflow the container */
    max-height: 170px; /* Limit the image height */
    object-fit: contain; /* Maintain aspect ratio */
    margin-bottom: 10px; /* Space between image and label */
    border-radius: 4px; /* Optional: rounded corners for the image */
}

.choice-card .status-message {
    font-size: 14px;
    color: #ff9800;
    margin-top: 10px;
    font-weight: 600;
    text-align: center;
}

.choice-card .gujrati {
    font-size: 18px;
    margin-top: 5px;
    font-weight: 600;
}

.choice-card-slip-size:hover,
.choice-card:hover {
    background-color: #d9b3ff;
    color: #4b0082;
    transform: scale(1.05);
    border: 2px solid #9933ff;
}

.choice-card-slip-size.selected,
.choice-card.selected {
    background-color: #b535ec;
    color: #ffdf02;
    border: 2px solid #6600cc;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.choice-card .icon-container {
    margin-top: 10px;
    font-size: 20px;
    color: #6600cc;
    transition: transform 0.3s, color 0.3s;
}

.modal-actions {
    position: absolute;
    left: 2%;
    bottom: 2%;
    margin-top: 9px;
    display: flex;
    justify-content: space-between;
}

.close-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 10px;
    border-bottom-left-radius: 8px  ;
    transition: transform 0.3s, background-color 0.3s;
}

.close-icon:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-actions .back-btn {
    padding: 10px 20px;
    background-color: #b535ec;
    color: white;
    border: 2px solid #6600cc;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s, transform 0.2s, border 0.3s;
}

.modal-actions .back-btn:hover {
    color: #f4f3f8;
    border: 2px solid #9933ff;
    transform: scale(1.05);
}

/* Step 6 Styling */
.detail-container {
    margin-top: 20px;
}

.election-name-input{
    max-width: 50%;
}

.inp-group {
    margin-bottom: 20px;
}

.inp-group.inline {
    display: flex;
    gap: 15px;
    align-items: flex-start;
}

.inp-group label {
    display: block;
    font-size: 18px;
    font-weight: bold;
    color: rgb(100, 2, 100);
    margin-bottom: 5px;
}

.inp-group .gujrati {
    font-size: 16px;
    color: #9933ff;
    font-weight: bold;
    margin-left: 5px;
}

.inp-group input, .inp-group select {
    width: 100%;
    padding: 8px;
    border: 2px solid #9933ff;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.image-upload-container {
    display: flex;
    align-items: center;
    gap: 15px;
}

.upload-field {
    flex: 1;
}

.image-preview {
    max-width: 30%;
    max-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.image-preview img {
    width: 100%;
    height: auto;
    max-height: 200px;
    object-fit: contain;
}

.print-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.print-btn {
    padding: 12px 25px;
    font-size: 18px;
    font-weight: bold;
    background-color: #9933ff;
    color: white;
    border: 2px solid #6600cc;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.print-btn:hover {
    background-color: #6600cc;
    transform: scale(1.05);
}

.option-btn {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    background-color: #9933ff;
    color: white;
    border: 2px solid #6600cc;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    gap: 10px;
}

.option-btn:hover {
    background-color: #6600cc;
    transform: scale(1.05);
}
/* 
.delivery-option .form-group {
margin-top: 20px;
} */

.error-mess {
    color: red;
    font-size: 16px;
    font-weight: bold;
}

/*Step 7 Order Summary */
.billing-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    padding: 20px;
  }
  
  .billing-details {
    flex: 1;
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .qr-container {
    flex: 1;
    text-align: center;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .billing-title {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333;
    text-align: left;
  }
  
  .billing-row {
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    padding: 8px 0;
  }
  
  .billing-value {
    font-weight: bold;
    color: #444;
  }
  
  .text-red {
    color: red;
  }
  
  .text-green {
    color: green;
  }
  
  .qr-code-box {
    display: inline-block;
    padding: 10px;
    background: white;
    border-radius: 10px;
  }
  
  .qr-instructions {
    font-size: 1rem;
    color: #666;
    margin-top: 10px;
  }
  
  .modal-action-2-btn {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .back-btn, .confirm-btn {
    padding: 10px 20px;
    font-size: 1.1rem;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .back-btn {
    background: #ddd;
    color: #333;
  }
  
  .confirm-btn {
    background: #28a745;
    color: white;
  }
  
.modal-action-2-btn {
    position: absolute;
    left: 2%;
    bottom: 2%;
    width: 96%;
    margin-top: 9px;
    display: flex;
    justify-content: center;
}

.confirm-btn {
    background-color: #9b59b6;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
}
.modal-action-2-btn .back-btn {
    padding: 10px 20px;
    background-color: #9933ff;
    color: white;
    border: 2px solid #6600cc;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s, transform 0.2s, border 0.3s;
}
.modal-action-2-btn .back-btn:hover {
    background-color: #6600cc;
    color: #f4f3f8;
    border: 2px solid #9933ff;
    transform: scale(1.05);
}

.confirm-btn:hover {
    opacity: 0.9;;
}


/* Order Confirmation Styling */
.order-confirmation {
    background-color: #f3e8ff;
    border: 2px solid #9c4dcc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    width: 40%;
    margin: 30px auto;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.order-confirmation h3 {
    font-size: 24px;
    color: #9c4dcc;
    margin-bottom: 10px;
}

.order-confirmation p {
    font-size: 18px;
    color: #4a2c92;
    margin-bottom: 20px;
}

.order-confirmation button {
    background-color: #9c4dcc;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.order-confirmation button:hover {
    background-color: #7a2b9d;
}

@media (max-width: 610px) {
    .choice-modal {
        width: 95vw;
        height: 85vh;
        padding: 5px;
        border-radius: 5px;
    }

    .choice-modal h3 {
        font-size: 24px;
        margin-top: 10px;
    }

    .choices-container {
        gap: 10px;
        height: max-content;
    }

    .choices-container.grid-2x2,
    .choices-container.grid-2x3 {
        grid-template-columns: repeat(2, 1fr);
    }

    .choice-card {
        width: 90%;
        height: auto;
        font-size: 18px;
        padding: 15px;
    }

    .choice-card .gujrati {
        font-size: 16px;
    }

    .print-button-container {
        display: flex;
        flex-direction: column;
        align-items: end;
    }
}

/* ✅ Responsive Styles - Mobile: Stack billing and QR in 2 rows */
@media screen and (max-width: 768px) {
    .billing-container {
        display: flex;
        flex-direction: column;
    }

    .billing-details,
    .qr-container {
        width: 100%;
    }

    .billing-row {
        font-size: 14px;
    }

    .qr-container {
        padding: 8px;
    }

    .confirm-btn {
        font-size: 14px;
        padding: 8px 16px;
    }
    .election-name-input{
        max-width: 100%;
    }
}

.transition-div{
    height: 90vh;
}

.step-enter {
    opacity: 0;
    transform: translateX(100%);
}

.step-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms ease, transform 500ms ease;
}

.step-exit {
    opacity: 1;
    transform: translateX(0);
}

.step-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 500ms ease, transform 500ms ease;
}
.step-reverse-enter {
    opacity: 0;
    transform: translateX(-100%);
}

.step-reverse-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms ease, transform 500ms ease;
}

.step-reverse-exit {
    opacity: 1;
    transform: translateX(0);
}

.step-reverse-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 500ms ease, transform 500ms ease;
}