.main-container {
    display: flex;
    height: 100vh;
    font-family: 'Arial', sans-serif;
}

.content-container {
    flex-grow: 1;
    background-color: #f4f4f4;
    overflow-y: auto;
}

.orders-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #5a155d;
    color: #ffdf02;
    padding: 5px 30px;
    margin-bottom: 20px;
}

.shop-name {
    font-size: 24px;
    font-weight: bold;
}

.credits-container {
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.credits {
    font-size: 18px;
    color: #5a155d;
    display: flex;
    align-items: center;
    gap: 8px;
}

.credits .amount,
.credits .status {
    font-weight: bold;
}

.amount.red-text,
.status.red-text {
    color: red;
}

.amount.green-text,
.status.green-text {
    color: green;
}

.credits .status {
    font-size: 16px;
}

.credits-container p {
    margin: 0;
    padding: 0;
}

.credits-container .fa-credit-card {
    font-size: 20px;
    margin-right: 8px; /* Space between the icon and text */
}

.table-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 80%;
    overflow-y: auto;
    position: relative;
}

.orders-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
}

.orders-table thead {
    background-color: #5a155d;
    color: #ffdf02;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
}

.order-date-clm{
    min-width: 100px;
}

.slip-size-clm{
    min-width: 70px;
}

.slip-type-clm{
    min-width: 100px;
}

.orders-table th,
.orders-table td {
    text-align: left;
    padding: 4px 8px;
    border: 1px solid #ddd;
}

.orders-table th {
    font-size: 16px;
    font-weight: bold;
}

.orders-table td {
    font-size: 14px;
}

.orders-table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.orders-table tbody tr:nth-child(even) {
    background-color: #ffffff;
}

.order-image {
    width: 80px;
    height: auto;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    object-fit: cover;
}

.table-container::-webkit-scrollbar {
    width: 8px;
}

.table-container::-webkit-scrollbar-thumb {
    background-color: #5a155d;
    border-radius: 4px;
}

.table-container::-webkit-scrollbar-track {
    background-color: #f4f4f4;
}

/* Message container for success/failure message */
.message-container {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    padding: 50px 0;
    color: #d9534f; /* Red color for error messages */
}

.message-container p {
    margin: 0;
}

/* If the message is success */
.message-container.success {
    color: #5bc0de;
}

/* If the message is error */
.message-container.error {
    color: #d9534f;
}

/* Style for the download button */
.download-btn {
    display: flex; /* Aligns the icon inside the button */
    align-items: center;
    justify-content: center;
    background-color: #007bff; /* Blue background */
    color: white; /* White text/icon color */
    border: none; /* Remove border */
    border-radius: 4px; /* Rounded corners */
    padding: 8px 12px; /* Padding around the icon */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 18px; /* Font size for icon */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition for hover effect */
  }
  
  .download-btn:hover {
    background-color: #0056b3; /* Darker blue background on hover */
    transform: scale(1.05); /* Slightly enlarge the button on hover */
  }
  
  .download-btn svg {
    margin-right: 5px; /* Space between icon and text */
  }
  
  .download-btn:focus {
    outline: none; /* Remove focus outline */
  }
  
  .download-btn:disabled {
    background-color: #dcdcdc; /* Disabled state color */
    cursor: not-allowed; /* Cursor for disabled button */
  }
  
  .download-btn:disabled svg {
    color: #aaa; /* Change icon color when disabled */
  }

@media (max-width: 768px) {
    .orders-header {
        flex-direction: column;
        align-items: flex-start;
        padding: 15px;
    }

    .credits p {
        margin-top: 10px;
    }

    .orders-table {
        font-size: 12px;
    }

    .order-image {
        width: 60px;
    }
}


.new-order-container {
    margin: 5px 10px;
    text-align: right;
}

.new-order-btn {
    display: inline-flex;
    align-items: center;
    background-color: #5a155d;
    color: #ffdf02;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s ease;
}

.new-order-btn .icon {
    margin-right: 8px;
}

.new-order-btn:hover {
    background-color: #832491; /* Slightly lighter violet on hover */
    transform: scale(1.05);
}
