.transactions-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #5a155d;
    color: #ffdf02;
    padding: 5px 30px;
    margin-bottom: 20px;
}

.shop-name {
    font-size: 24px;
    font-weight: bold;
}

.credits-container {
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.credits {
    font-size: 18px;
    color: #5a155d;
    display: flex;
    align-items: center;
    gap: 8px;
}

.credits .amount,
.credits .status {
    font-weight: bold;
}

.amount.red-text,
.status.red-text {
    color: red;
}

.amount.green-text,
.status.green-text {
    color: green;
}

.credits .status {
    font-size: 16px;
}

.credits-container p {
    margin: 0;
    padding: 0;
}

.credits-container .fa-credit-card {
    font-size: 20px;
    margin-right: 8px;
}

.red-text-td{
    color: red;
}

.green-text-td{
    color: green;
}

/* Table Container */
.table-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 80%;
    overflow-y: auto;
    position: relative;
}

/* Transactions Table */
.transactions-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
}

.transactions-table thead {
    background-color: #5a155d;
    color: #ffdf02;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
}

.transactions-table th,
.transactions-table td {
    text-align: left;
    padding: 4px 8px;
    border: 1px solid #ddd;
}

.transactions-table th {
    font-size: 16px;
    font-weight: bold;
}

.transactions-table td {
    font-size: 14px;
}

.transactions-table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.transactions-table tbody tr:nth-child(even) {
    background-color: #ffffff;
}

/* Scrollbar Styling */
.table-container::-webkit-scrollbar {
    width: 8px;
}

.table-container::-webkit-scrollbar-thumb {
    background-color: #5a155d;
    border-radius: 4px;
}

.table-container::-webkit-scrollbar-track {
    background-color: #f4f4f4;
}

/* Add loading message styling */
.loading-message {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #5a155d;
    padding: 50px 0;
}

/* Message container for success/failure message */
.message-container {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    padding: 50px 0;
    color: #d9534f; /* Red color for error messages */
}

.message-container p {
    margin: 0;
}

/* If the message is success */
.message-container.success {
    color: #5bc0de;
}

/* If the message is error */
.message-container.error {
    color: #d9534f;
}

/* Responsive Design */
@media (max-width: 768px) {
    .transactions-header {
        flex-direction: column;
        align-items: flex-start;
        padding: 15px;
    }
    
    .credits p {
        margin-top: 10px;
    }
    
    .transactions-table {
        font-size: 12px;
    }
}
